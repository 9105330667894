const defaultLogUrl = process.env.LOG_URL;

const scriptTag = document.getElementById('kk-global-error-handler');
const url = scriptTag?.getAttribute('data-kk-log-url') || defaultLogUrl;
const isDebugMode = JSON.parse(scriptTag?.getAttribute('data-kk-debug-mode') || false);

export default function send(...args) {
    if (args.length === 0) {
        throw new Error('Empty arguments');
    }

    const errorLog = Object.assign({
        time: new Date(),
        domain: window.location.host,
        path: window.location.pathname,
    }, ...args);

    if (isDebugMode) {
        console.log(errorLog);
    } else {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(errorLog),
            keepalive: true,
            referrerPolicy: 'unsafe-url',
        })
            .catch(err => {
                console.error(err);
            });
    }
}
