import send from './send';

const _window = window || global.window;
const scriptTag = document.getElementById('kk-global-error-handler');
const vueInstanceNames = scriptTag?.getAttribute('data-kk-vue-instance');
const vueInstanceList = [];

if (vueInstanceNames) {
    vueInstanceNames.split(',').forEach(name => vueInstanceList.push(name.trim()));
}

_window.logDataLayer = {
    vueGlobalErrorHandler: null,
    vueRouterGlobalErrorHandler: null,
};

// js異常
window.addEventListener(
    'error',
    event => {
        const {
            type,
            filename,
            message,
            lineno,
            error: { stack },
        } = event;

        send({
            errorType: 'javascript',
            type,
            filename,
            message,
            stack,
            line: lineno,
        });
    },
);

// promise異常
window.addEventListener(
    'unhandledrejection',
    event => {
        const {
            type,
            filename,
            reason: { message, stack },
        } = event;

        send({
            errorType: 'javascript-promise',
            type,
            filename,
            message,
            stack,
        });
    },
);

// vue異常
_window.logDataLayer.vueGlobalErrorHandler = (err, vm, info) => {
    const data = {
        errorType: 'vue',
        message: err.message,
        stack: err.stack,
        hook: info,
    };

    if (vm.$options.name) {
        data.componentName = vm.$options.name;
    }
    if (vm.$options._componentTag) {
        data.componentTag = `<${vm.$options._componentTag} />`;
    }
    if (vm.$route && vm.$route.name) {
        data.routeName = vm.$route.name;
    }

    send(data);

    _window.logDataLayer.vueUtilWarn(err.message, vm);
};

// vue router 異常
_window.logDataLayer.vueRouterGlobalErrorHandler = err => {
    send({
        errorType: 'vue router',
        message: err.message,
        stack: err.stack,
    });

    console.error(err);
};

// 綁定 errorHandler
try {
    vueInstanceList.forEach(vm => {
        const timeId = setInterval(() => {
            if (_window[vm] && _window[vm]._isVue) {
                _window[vm].constructor.config.errorHandler = _window.logDataLayer.vueGlobalErrorHandler;

                if (!_window.logDataLayer.vueUtilWarn) {
                    _window.logDataLayer.vueUtilWarn = _window[vm].constructor.util.warn;
                }

                if (_window[vm].$options.router) {
                    _window[vm].$options.router.onError(_window.logDataLayer.vueRouterGlobalErrorHandler);
                }

                _window.clearInterval(timeId);
            }
        }, 100);

        setTimeout(() => {
            _window.clearInterval(timeId);
        }, 5000);
    });
} catch (err) {
    if (typeof console !== 'undefined' && typeof console.error === 'function') {
        console.error(err);
    }
}
